header {
  height: 70px;
  background-color: #000;
  color: #b9b9b9;
  border-bottom: 2px solid #000;
}

.logo-container {
  display: inline-block;
  margin: 18px 0 0 10px;
  font-size: 24px;
}

.nav-container {
  margin-right: -15px !important;
  float: right;
}

.header-container {
  padding: 0 45px 0 20px;
}

.sub-header {
  background-color: transparent;
  width: 100%;
}

.sub-header-left, .sub-header-right {
  display: inline-block;
}

.edition-search {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.sub-header-social {
  width: 30px !important;
  margin-right:20px;
  padding-top: 5px;
}

.sub-header-right {
  padding: 10px 0 0 0;
  right: 28px;
  /* float: right; */
  position: absolute;
}

@media screen and (max-width: 600px) {
  header {
    position: fixed;
    top: -1px;
    width: 100%;
    z-index: 9;
  }

  .sub-header {
  margin-top: 80px;
  }

  .sub-header-right {
    padding: 10px 0 0 0;
    right: 10px;
    /* float: right; */
    position: absolute;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    background: rgb(0, 0, 0);
    padding: 0 15px 0 10px;
  }

  .nav-container {
    margin-right: 0 !important;
    float: none;
  }

  .logo-container {
    display: inline-block;
    margin: 18px 0 0 0px;
    font-size: 24px;
  }
  .edition-search{
    width: 180px !important;
  }
}
