.collage-gallery-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
}
.collage-bot-image-wrapper {
    margin: 5px;
    width: 258px;
    height: 125px;
    cursor: pointer;
}

.collage-gallery-bot {
    display: block;
    margin: 0 auto;
    width: 115px;
    margin-top: 10px;
    image-rendering: pixelated;
  }
  .collage-bot-image-wrapper:hover {
    outline:2px solid rgb(255, 255, 255);
  }

.college-gallery-edition{
    position: absolute;
    margin:8px 0 0 15px;
    font-weight: 600;
    font-size: 20px;
    color:#000000;
}

.collage-gallery-edition-num {
    font-weight: 300;
    font-size: 12px;
}