
.pagination li{
    margin: 5px;
}
.page-number {
    cursor: pointer;
    padding: 10px 10px;
}
.page-number:hover {
    background-color: #282828;
}

.active-page {
    font-weight: 700;
    background-color: #000;
}

@media screen and (max-width: 600px) {
.pagination-item, .page-number {
    display: none;
}
.pagination {
    text-align: center;
}
}