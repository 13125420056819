ul li.tag:first-child {
  margin: 5px 10px;
}



ul li.tag {
  display: inline-block;
  padding: 5px 25px 10px 10px;
  margin: 5px 10px;
  font-weight: 400;
}



/* ul li.tag:hover {
  background-color:#f1f1f1;
  color: #000;
  cursor: pointer;
} */

.tag-title {
    font-size: 12px;
    font-weight:300;
    margin-bottom: 3px;
    color: #f1f1f1;
}

@media screen and (max-width: 600px) {

  ul li.tag:nth-child(3) {
    display: block;
  }
  ul li.tag:nth-child(4) {
    display: block;
  }
}
