.loading {
  color: #282828;
  text-align: center;
  width: 500px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 1500px;
}

.transaction-loader{
  color:black;
  text-align: center;
  width: auto;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 500px;
}

@media screen and (max-width: 600px){

  .loading {
    color: #282828;
    text-align: center;
    width: 300px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 1500px;
  }

}
