.container {
  max-width: calc(100% - 10px);
  width: 1000px;
  margin: 0 auto;
  padding-top: 30px;
  margin-top: 50px;
  min-height:80vh; 
  margin-bottom: 150px;
}
.bot-portrait-wrapper {
  max-height: 300px;
  position: relative;
  text-align: center;
  border-radius: 5px;
}
.ada-bot-img {
  width: 250px;
  display: block;
  margin: auto;
  cursor: pointer;
  image-rendering: pixelated;
}
h2.edition-number{
  font-size: 25px;
  font-weight: 300;
}

.edition {
  font-size: 3rem;
  position: absolute;
  top: 20px;
  left: 25px;
  color: #000;
  text-align: left;
}
.creators-edition {
  color: #d4af37; /*CCAD34*/
}

.bot-attributes {
  height: 500px;
}
.dna {
  color: #282828;
  padding: 15px;
  width: 350px;
}
.bot-attributes ul {
  height: auto;
}
.bot-attributes h2 {
  padding-left: 20px;
  padding-top: 25px;
}
.edition-title {
  font-size: 20px;
  font-weight: 300;
  text-align: left;
}
.previous-bot-btn {
  position: absolute;
  cursor: pointer;
  top: 110px;
  left: 20px;
  color: #272727;
}
.next-bot-btn {
  position: absolute;
  cursor: pointer;
  top: 110px;
  right: 20px;
  color: #272727;
}

.Cyan {
  background-color: #6dcff6;
}
.Yellow {
  background-color: #ffff00; /**/
}
.Red {
  background-color: #ff0000;
}
.Rose {
  background-color: #ed145b;
}

.Neon-Yellow {
  background-color: #d9ff16;
}
.Green {
  background-color: #2fc3a6;
}

.White {
  background-color: #ffffff;
}
.Lavender {
  background-color: #c08ce4;
}
.Blue {
  background-color: #2a00d3;
}
.Taupe {
  background-color: #cdbeb7;
}
.Graphite {
  background-color: #363636;
}

.bp3-drawer-menu {
  background-color: #000 !important;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
  .edition {
    font-size: 3rem;
    text-align: left;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .container {
    display: inline-block;
  }
  .mb-container {
    margin-top: 20px !important;
  }
  .sample-disclamier {
    padding: 2px 5px 0 10px;
    color: #666;
  }

  .edition {
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    left: 15px;
    color: #000;
    text-align: left;
  }

  .dark-edition {
    color: darkgray !important;
  }

  .creators-edition {
    color: #d4af37; /*CCAD34*/
    text-align: left;
  }

  .edition-title {
    font-size: 16px;
  }

  .dna {
    font-size: 4vw;
  }

  .bot-portrait-wrapper {
    max-height: 300px;
    position: relative;
    text-align: center;
    width: 100vw;
    overflow-x: hidden !important;
    border-radius: 5px;
  }
  .container {
    margin-top: 5px;
  }
  .previous-bot-btn {
    margin-right: 15px;
    top: 75px;
  }

  .next-bot-btn {
    margin-right: 0px;
    top: 75px;
  }

  .active {
    color: #ffff00;
  }
  .large-btn {
    padding: 20px !important;
    font-size: 1.5rem !important;
    width: 20rem;
  }
  .ada-bot-img {
    width: 200px;
    display: block;
    margin: auto;
    image-rendering: pixelated;
  }
}

/* 
#6dcff6 cyan
#ffff00 yellow
#ff0000 red
#ed145b rose
#d9ff16 Neon Yellow

*/
