.social {
  font-size: 16px;
  margin: 10px 0;
}
.slogan {
  font-size: calc(24.0012px);
  margin-top: -15px;
  margin-left:5px;
}
.home-container {
  padding: 25px 10px;
  max-width: 1000px;
  margin: 0 auto;
}
.home-container span {
  font-size: 18px;
}
.policy-id-container {
  font-size: 1.2em;
}

.policy-id {
  font-size: 16px;
  overflow-wrap: break-word;
}

.Buy-link {
  padding: 0 5px 0 0;
  font-weight: 700;
}
div.bot {
  margin-top: -4px;
}
.bot {
  cursor: pointer;
  display: inline-block;
  margin: 0px;
}

.bot-img {
  width: 45px;
  image-rendering: pixelated;
}

.bot-img-home {
  image-rendering: pixelated;
}

.bot-img-home:hover {
  border: 1px solid #f5f5f5;
}

.bot-img:hover {
  border: 1px solid #f5f5f5;
}
.collage-container {
  margin: 30px 0;
}
.collection-desc {
  font-weight: 300;
  color: #f1f1f;
}
.container-m {
  margin: 0 auto;
  max-width: 1700px;
}

.small {
  width: 60px;
}

.large {
  width: 125px;
}

.external-link {
  font-weight: 700;
}
.external-link:hover {
  text-decoration: underline;
}
.banner {
  image-rendering: pixelated;
  display: block;
  /* margin: 0 auto; */
  width: 750px;
  margin-top: 27px;
}

section h1 {
  font-weight: 300;
  line-height: 1.2em;
  font-size: calc(48.0006px);
  margin: 40px 0;
}
section p {
  margin-bottom: 10px;
  line-height: 1.8em;
  font-size: 18px;
  max-width: 800px;
}

section h3 {
  font-size: calc(24.0012px);
  font-weight: 500;
}

/*GALLERY IMAGES */
.Gallery {
  /* display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f5f5f5;
  opacity: 0;
  transform: scale(1.2);
  transition: none;
  padding: 18rem 0;
  overflow-y: scroll; */
}

.Gallery-edition {
  position: absolute;
  margin: 5px 0 0 10px;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  /* text-shadow: 0px 0.1px #2c2c2c; */
}
.Gallery-edition-top {
  position: absolute;
  margin: 5px 0 0 10px;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.yy:hover > .Gallery-edition {
  color: black;
}
.y:hover > .Gallery-edition {
  color: black;
}

.Gallery-image:hover > .Gallery-edition {
  color: black;
}

.Gallery-image--primary:hover > .Gallery-edition-lg {
  color: black;
}

.Gallery-edition-num {
  font-weight: 300;
  font-size: 14px;
}

.Gallery-edition-lg {
  position: absolute;
  margin: 15px;
  font-weight: 600;
  font-size: 36px;
  /* color: rgb(187, 187, 187); */
  color: black;
}

.rarity-color {
  /* color: rgb(187, 187, 187); */
  color: black;
}

.Gallery-edition-num-lg {
  font-weight: 300;
  font-size: 20px;
}

.Gallery-images-img {
  image-rendering: pixelated;
}

.Gallery-images {
  display: flex;
  width: 47rem;
  /* margin: 0 auto; */
  justify-content: space-between;
  margin-bottom: 1rem;
  cursor: pointer;
}

/* .Gallery-image:hover {
  border: 1px solid #fff;
} */
/* .Gallery-images:nth-child(3) {
  transition: all 0.2s cubic-bezier(0.7, 0, 0.78, 1) 0.33s;
  opacity: 0;
  transform: translate(0, 3rem) scale(1.1);
}
.Gallery-images:nth-child(4) {
  transition: all 0.2s cubic-bezier(0.7, 0, 0.78, 1) 0.44s;
  opacity: 0;
  transform: translate(0, 3rem) scale(1.1);
}
.Gallery-images:nth-child(5) {
  transition: all 0.2s cubic-bezier(0.7, 0, 0.78, 1) 0.55s;
  opacity: 0;
  transform: translate(0, 3rem) scale(1.1);
}
.Gallery-images:nth-child(6) {
  transition: all 0.2s cubic-bezier(0.7, 0, 0.78, 1) 0.66s;
  opacity: 0;
  transform: translate(0, 3rem) scale(1.1);
} */
.Gallery-bot {
  display: block;
  margin: 0 auto;
  width: 152px;
  /* margin-top: 10px; */
}

.Gallery-bot-lg {
  display: block;
  margin: auto;
  width: 320px;
  /* margin-top: 20px; */
}

.Gallery-left {
  flex: 1 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.Gallery-image {
  display: block;
  width: 15rem;
  height: 9.5rem;
  transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}
.Gallery-image:hover {
  /* outline: 2px solid white; */
}
.Gallery-image--primary {
  width: 31rem;
  height: 20rem;
  border-radius: 5px;
}
.Gallery--active {
  z-index: 100;
  background: #fff;
  transform: scale(1);
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.7, 0, 0.78, 1) 0.1s;
}
.Gallery--active .Gallery-close {
  display: block;
}
.Gallery--active .Gallery-images {
  opacity: 1;
  transform: none;
}

.mobile-site {
  display: none !important;
}

@media only screen and (min-width: 320px) and (max-width: 370px) {
  .full-site,
  .Gallery-edition-top {
    display: none !important;
  }
  .mobile-site {
    display: inline !important;
  }
  .Gallery-image:hover {
    outline: none;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px 
  @media screen and (max-width: 600px) */
@media screen and (max-width: 600px) {
  .full-site,
  .Gallery-edition-top {
    display: none !important;
  }
  .Gallery-image:hover {
    outline: none;
  }

  .mobile-site {
    display: inline !important;
  }
  .Gallery-images {
    display: inline-block !important;
    width: 47% !important;
    /* margin: 0 auto; */
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-right: 8px;
    cursor: pointer;
  }
  .Gallery-image--primary {
    width: auto;
    height: auto;
  }

  .policy-id {
    margin-left: 3px;
    overflow-wrap: break-word;
  }

  .Gallery-edition-lg {
    font-size: 1.7em;
  }

  .Gallery-left {
    display: block;
  }
  .Gallery-image {
    width: auto;
    height: auto;
  }
  .Gallery-bot {
    width: 9.5rem;
    margin: 10px;
  }
  .collage-bot-image-wrapper {
    margin: 5px;
    width: 160px;
    height: 115px;
    cursor: pointer;
    display: inherit;
  }

  .collage-gallery-bot {
    display: block;
    margin: 0 auto;
    /* width: auto; */
    margin-top: 0px;
    image-rendering: pixelated;
  }

  .college-gallery-edition {
    position: absolute;
    margin: 8px 0 0 15px;
    font-weight: 600;
    font-size: 0.8rem;
    color: #000000;
  }
  .wrapper {
    margin-top: 50px;
  }

  .banner {
    width: 100%;
  }

  section h1 {
    font-weight: 300;
    line-height: 1.2em;
    font-size: calc(48.0006px);
    margin: 0px 0;
  }
}
