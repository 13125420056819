.edition-search {
    height: 50px;
}

.look-up-icon {
    cursor: pointer;
    margin-right: 20px;
    vertical-align: baseline;
    color:#999;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}