.app {
  min-height: 100vh;
}
.search-container,
.paging-container {
  display: block;
  display: table;
  margin: 0 auto;
  margin-top: 10px;
}

.search-container h1 {
  text-transform: uppercase;
  font-weight: 300;
}

.search-results,
.search-results-found {
  /* padding: 25px 0px 25px 0px; */
  padding: 25px 10px;
  max-width: 1200px;
  margin: 0 auto;
}

.b {
  padding: 10px 50px;
  max-width: 1000px;
  margin: 0 auto;
}
.filter-input-wrapper {
  display: inline-block;
  margin: 15px;
}

.filter-input {
  display: inline-block !important;
  width: 325px;
}

.filter-btns {
  margin-left: 2px;
}
.results-text {
  padding: 10px;
  text-align: center;
}

.xx {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
}
.yy {
  margin: 1.1em;
  width: 250px;
  height: 125px;
  cursor: no-drop;
}
.yy:hover {
  outline: 2px solid #282828;
}
.Explore-Gallery-bot,
.bot-img {
  display: block;
  margin: 0 auto;
  margin-top: 2px;
  width: 123px;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 640px) {
  /* .container {
    height: 10000;
  } */
  .scrollToTopBtn {
    /* display: inline-block; */
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 2;
    border: none;
    outline: none;
    background-color: #000;
    color: white;
    cursor: pointer;
    padding: 15px;
    /* border-radius: 10px; */
  }

  .buy-now-btn {
    /* display: inline-block; */
    position: fixed;
    bottom: 150px;
    right: 0px;
    z-index: 2;
    border: none;
    outline: none;
    background-color: #0f9960;
    color: white;
    font-weight: 500;
    font-size: 1.2em;
    cursor: pointer;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 2px 3px 5px #030303;
    right: 0%;
    transform: translateX(-15%);
    width: 250px;
    text-align: center;
  }

  .paging-container {
    display: table;
  }

  .paging-container-mobile {
    display: none;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .xx {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
    margin: 0 auto;
    min-width: 100px;
  }

  .ada-bot-mobile {
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    width: 175px;
  }
  .search-results {
    padding: 0 !important;
    margin-top: 150px;
  }

  .search-results-found {
    padding: 0 !important;
    margin-top: 190px;
  }
  .container {
    height: 100vh;
    margin-top: 100px;
  }
  .scrollToTopBtn {
    /* display: inline-block; */
    position: fixed;
    bottom: 100px;
    right: 30px;
    z-index: 2;
    border: none;
    outline: none;
    background-color: #000;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
  }

  .buy-now-btn {
    /* display: inline-block; */
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 2;
    border: none;
    outline: none;
    background-color: #0f9960;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 3px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    font-weight: 700;
    text-align: center;
  }

  .filter-input-wrapper {
    display: inline-block;
    margin: 15px;
    width: 100%;
  }
  .paging-container-mobile {
    margin: 10px 0;
  }
  .search-container {
    position: fixed;
    background-color: #121212;
    top: 65px;
    width: 100%;
    z-index: 9;
  }
  .paging-container {
    display: none;
  }
  .paging-container-mobile {
    display: block;
  }
  .yy {
    width: 43vw;
    height: 151px;
    margin: 0.5rem;
  }
  .yy-search-result {
    width: 100vw;
  }
  .Explore-Gallery-bot {
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    width: 136px;
  }
  .Gallery-edition-num {
    font-size: 0.7em;
  }

  .Gallery-edition {
    font-size: 1.3em;
  }
  .Gallery-edition-num-sr {
    font-weight: 300;
    font-size: 0.5em !important;
  }

  .filter-input {
    display: inline-block !important;
    width: 70%;
  }
}
