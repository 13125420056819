.logo {
    cursor: pointer;
    cursor: pointer;
    width: 4.7em;
    margin-top: 5px;
    image-rendering: pixelated;
}
.logo-icon {
    image-rendering: pixelated;
    object-fit: cover;
    border-radius:50%;
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 600px) {
    .logo {
        cursor: pointer;
        cursor: pointer;
        width: 4.7em;
        margin-top: 5px;
        image-rendering: pixelated;
    }
}