.bp3-dialog-body {
 color: #282828 !important;
}
.buy-btn{
  width: 250px !important;
  margin-top: -8px;
}
ul {
  list-style: none;
  /* margin-right: 15px; */
  padding: 0;
  height: 100%;
}

ul li {
  display: inline-block;
  margin: 9px 15px;
}

ul li a {
  display: block;
  padding-bottom: 5px;
  text-decoration: none;
  /* text-transform: uppercase; */
  font-weight: 600;
  color: rgb(99, 99, 99);

}

li a:hover {
  /* background-color: #fff; */
  /* border-bottom: 2px solid #282828; */
  text-decoration: none;
  color: rgb(145, 145, 145);
}

.active {
  /* border-bottom: 2px solid #282828; */
  font-weight: 700;
  color: #ffff;
}

a.active {
  border-bottom: none !important;
}

.qr-code-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 25px 0;
}
.wallet-qr-code {
  width: 250px;
  height: 250px;
  image-rendering: pixelated;
}
.price-info-container {
  margin: 10px 0 40px 0;
  padding: 0 25px;
}

.callout-header {
  margin: 10px 0 2px 0;
}

.q-a-container p{
margin-bottom: 10px;
}

.q-a-header {
  margin: 20px 0 10px 0;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
.mobile-menu{
  display: none;
}
.full-screen-menu{
  display: inline-block;
}
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px 
@media screen and (max-width: 600px) */
@media screen and (max-width: 600px){
  .bp3-drawer.bp3-position-top{
    height: auto !important;
  }
  .mobile-menu{
    display: block;
}

.full-screen-menu{
  display: none;
}

.mobile-ul {
  text-align: center;
}

.mobile-ul li{
  display: block;
  margin: 30px 15px;
}

.mobile-ul li a{
  font-size: 2rem;
}
.active {
  font-weight: 700;
  color: #000;
}
}



