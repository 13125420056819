footer {

    min-height: 100px;
    background-color: #000;
    color: #737373;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    padding: 0px;
    /*#363636*/
}
.footer-content {
  padding-top: 20px;
}

.cardano-logo {
    width: 150px;
    padding: 10px;
    margin-bottom: 10px;
}

.social-media-logos{
    width: 25px;
    cursor: pointer;
}
.misfitBots-image {
  margin-top:150px;
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(15, 15, 15, 0.5)), url("/src/preview.png");
  image-rendering: pixelated;
  /* Set a specific height */
  height: 35vh;

  /* Position and center the image to scale nicely on all screens */
  background-position: left;
  /* background-repeat: space no-repeat; */
  /* background-size: cover; */
  /* position: relative; */
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
    .edition {
      font-size: 3rem;
    }
  }
  
  /* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
  @media screen and (max-width: 600px) {


   
  }