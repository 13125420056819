.stepper-container {
  margin-top: 5px;
  padding: 15px;
}
.step {
  position: relative;
  /* min-height: 5em; */
  color: #f1f1f1;
}
.title {
  line-height: 1.6em;
  font-weight: bold;
  font-size: 1.4em;
  color: #f1f1f1;
}
.caption {
  font-size: 1.2em;
}
.step + .step {
  margin-top: 1.4em;
}

.step > div:first-child {
  position: static;
  height: 0;
}

.step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
  margin-top: -6px;
}

/* Circle */
.circle {
  background: #282828;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 2px #fff;
}

/* Vertical Line */
.circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: 10px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 90%;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: rgba(180, 180, 180, 0.25);
  z-index: -1;
}

.step:last-child .circle:after {
  display: none;
}

.step.step-active {
  color: #4285f4;
}
.step.step-active .circle {
  background-color: #4285f4;
}

@media screen and (max-width: 600px) {
  .title {
    line-height: 1.6em;
    font-weight: bold;
    font-size: 1.2em;
    color: #f1f1f1;
  }

  .stepper-container {
    margin-top: 20px;
    padding: 15px;
  }
}
