.timer {
    border: 5px solid #004853;
    display:inline-block;
    padding: 5px 0 1px 0px;
    color: #004853;
    font-family: Verdana, sans-serif, Arial;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;  
    width: 100%;
    margin: 10px 0px;
    text-align: center;
}