.roadmap-stepper-container {
    margin-top: 10px;
    padding: 15px;
}

.roadmap-stepper-container h3 {
    margin-bottom: 2px;
    font-weight: 400;
}
.roadmap-circle {
    background: #f1f1f1; 
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 2px f1f1f1; 
  }
  
  /* Vertical Line */
  .roadmap-circle:after {
    content: " ";
    position: absolute;
    display: block;
    top: 10px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 90%;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgba(180, 180, 180, 0.25);
    z-index: -1;
  }

  .step:last-child .roadmap-circle:after {
    display: none;
  }

  .roadmap-title {
    line-height: 1.6em;
    font-weight: 400;
    font-size: 1.4em;
    color: #f1f1f1;  
  }

  @media screen and (max-width: 600px) {
    .roadmap-title {
      line-height: 1.6em;
      font-size: 1.2em;
      color: #f1f1f1;
    }
  
    .stepper-container {
      margin-top: 20px;
      padding: 15px;
    }
  }