.misfitBot-container {
    display: inline-block;
    margin: 0 2px;
}

.first-bot {
    margin-bottom: 10px;
}
.reso-expired-msg {
    border: 2px solid #db3737;
    display:inline-block;
    padding: 10px;
    color: #db3737;
    font-family: Verdana, sans-serif, Arial;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none; 
    margin: 10px 8px;
    line-height: 26px; 
    background-color: #f8d7da;
}

.congrats-container {
    width: 100%;
    display: inline-block;
    margin-bottom: 1px;
    color: #0f9960; 
}

.congrats-container small {
    color: #272727;
    margin: 5px 0 0 80px;
    display: inline-block;
}
.congrats-container h2 {
    font-size: 32px;
    font-weight: 300;
    padding-top:15px;
    padding-left: 15px;
    margin-bottom: 20px;
    color: #0f9960; 
}


.congrats-container p, .details-container p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    padding-left: 15px;
    padding-right:10px;
    padding-bottom: 15px;
    color:#272727;
}

.details-container h3{
    font-size: 20px;
    font-weight: 300;
    padding-left: 15px;
    padding-right:10px;
}

.details-container h4{
    font-size: 18px;
    padding-left: 15px;
    padding-right:10px;
    font-weight: 500;
    line-height: 26px;;
}

.note {
    background-color: #f1f1f1;
    padding: 10px 10px;
}
.dialog-body{
    margin:20px !important
}
.middle {
    cursor: pointer;
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

.sold-bot-portrait-wrapper {
    position: relative;
    text-align: center;
}

.sold-bot-portrait-wrapper:hover .sold-misfit-img{
    opacity: 0.3;
    cursor: pointer;
}

.sold-bot-portrait-wrapper:hover .middle {
    opacity: 1;
}
.misfitBot-loc {
    cursor: pointer;
}

.text {
    background-color: #000000;
    color: white;
    font-size: 12px;
    padding: 5px;
  }

.sold-misfit-img {
    width: 70px;
    display: block;
    margin: auto;
    image-rendering: pixelated; 
}
.sold-misfit-img-lg {
    width: 150px;
    display: block;
    margin: auto;
    image-rendering: pixelated;   
}

.socialMediaButton {
    display: inline-block;
    margin: 0 25px 25px 15px;
    padding: 5px;
}
/* .buy-link{
    width: 200px;
}
.nav-buy-btn {
    width: 200px;
    height: 50px;
} */


