.sold-out-msg{
  width: 335px;
}
details {
  margin: 5px 0;
}
summary {
  cursor: pointer;
}

details p {
  padding: 10px 10px 10px 15px;
}
.buy-now-container {
  padding: 10px 0px;
  font-size: 16px;
  line-height: 22px;
}

.buy-now-container h3 {
  font-size: 24px;
  font-weight: 300;
}

.quanity-selection-container h3 {
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  padding: 5px 10px;
}

.buy-now-container ol{
  margin: 5px 15px 5px 0px;
}

.buy-now-container ol li{
  margin: 5px 5px 15px 0;
}
.disclaimer-msg {
  font-size: 16px;
}

.price-info-container {
  display: block;
}
.mobile-price-container {
  display: none;
}

.price-label {
  font-weight: 700;
  padding-left: 10px;
}
.price-help {
  padding-left: 10px;
}

.price-price {
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 3px;
}
.price-wallet-addy {
  font-size: 14px;
  overflow-wrap: break-word;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 3px;
}

.price-container {
  margin-bottom: 20px; 
}

.disclamer {
  display: block;
  width: 90%;
  margin: 0 auto;
  margin-top: 80px;
  padding: 25px;
}

.spinner {
  width: 10rem;
  height: 10rem;
}

.wrapper-spinner {
  position: relative;
  display: flex;
  justify-content: center;
  height: 10rem;
  margin:5rem 1rem 0;
  padding-top: 2.2rem;
  background: rgba(255, 255, 255, 0.2);
}

.wrapper-spinner::after {
  position: absolute;
  top: 200px;
  width: 100%;
  padding: 0.2rem;
  margin-top:25px;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  /* background: rgba(5, 5, 5, 0.25); */
  /* Increment the section counter */
  counter-increment: "Loading...";
  /* Display the counter on pseudo-elements */
  content: "Processing Transaction..."
}

.spinner.spinner7 {
  position: relative;
}
.spinner.spinner7::before, .spinner.spinner7::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
}
.spinner.spinner7::before {
  background: rgb(0, 0, 0);
  -webkit-animation: pulse2 2s ease-in-out infinite;
          animation: pulse2 2s ease-in-out infinite;
}
.spinner.spinner7::after {
  background: rgb(0, 0, 0);
  -webkit-animation: pulse2 2s 1s ease-in-out infinite;
          animation: pulse2 2s 1s ease-in-out infinite;
}

@-webkit-keyframes pulse2 {
  0%, 100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
@keyframes pulse2 {
  0%, 100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.quanity-selection-container{
  margin: 10px 0;
}
.btn-spacer {
  margin-top: 25px;
}

.select-quant {
  margin:10px 0px;
}

.quanity-selector li{
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  width: 125px;
  border: 1px solid #f1f1f1;
  border-radius: 3px;
}

.quanity-selector li:hover{
  background-color: blanchedalmond;
}


@media screen and (max-width: 600px) {
  .wrapper-spinner {
    position: relative;
    display: flex;
    justify-content: center;
    height: 10rem;
    margin: 3rem 1rem 0;
    padding-top: 2.2rem;
    background: rgba(255, 255, 255, 0.2);
  }

  .disclamer {
    display: block;
    width: 90%;
    margin: 0 auto;
    margin-top:115px;
  }
  
  .price-info-container {
    display: none;
  }

  .mobile-price-container {
    display: block;
    margin-bottom: 20px;
  }

  .price-container {
    display: none;
  }

  .mobile-label {
    font-weight: 700;
    padding-left: 10px;
  }
  .mobile-help {
    padding-left: 10px;
  }

  .mobile-price {
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 3px;
  }
  .mobile-wallet-addy {
    font-size: 14px;
    overflow-wrap: break-word;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 3px;
  }
}
