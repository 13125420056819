@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    /* font-family: 'Share Tech Mono'; letter-spacing: -2px; */
    /* font-size: 14px !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Courier New', monospace !important; */
  /* background-color: #fff8f8; */
  background-color: #272727;
  color: #f1f1f1;
  overflow-x: hidden !important;
}

a, a:hover{
  color: yellow;
}
.rare {
  font-size: 11px;
  color: black;
}

.rare-light {
  color:#fff !important;
}

.rare-lg {
  font-size: 16px;
  color: black;
}
.rare-details {
  color: black;
  position: absolute;
  right: 30px;
  top: 20px;
  -webkit-font-smoothing: antialiased;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bp3-input:disabled,
.bp3-input.bp3-disabled {
  color: #000 !important;
}

.bp3-button.bp3-outlined.bp3-intent-primary {
  color: #106ba3;
  font-weight: 600 !important;
}

.dialog-body {
  margin: 20px !important;
  font-size: 16px !important;
}
:focus {
  outline: none !important;
}

/* @media only screen 
and (min-device-width : 320px) {
  .bp3-drawer {
    width: 300px !important;;
  }
} */

@media only screen and (min-width: 320px) and (max-width: 370px) {
  .container {
    overflow-x: none !important; /* Hide horizontal scrollbar */
  }
  .bp3-drawer {
    width: 300px !important;
  }
  .large-btn {
    width: 12rem !important;
  }
  .mobile-menu {
    width: 100% !important;
  }
  .price-help {
    padding-left: 0 !important;
  }
  .quanity-selector li {
    display: inline-block;
    cursor: pointer;
    padding: 10px 2px !important;
    width: 100px !important;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
  }
}
