.confetti-container {
  width: 100%;
  height: 100%;
  display: inline;
 
}
.confetti {
  width: 15px;
  height: 7px;
  background-color: #4ef27f;
  position: absolute;
  left: 50%;
  top: -10px;
  animation: confetti 5s ease-in-out 1s 3;
  transform-origin: left right; 
  z-index: 4;
}
/* .confetti:nth-child(1) {
  background-color: #90f24e;
  left: 10%;
  animation-delay: -1.4;
} */
.confetti:nth-child(2) {
  background-color: #95c3de;
  left: 20%;
  animation-delay: -1s;
}
.confetti:nth-child(3) {
  background-color: #ff9a91;
  left: 30%;
  animation-delay: -3s;
}
.confetti:nth-child(4) {
  background-color: #f2d74e;
  left: 40%;
  animation-delay: -2.5s;
}
.confetti:nth-child(5) {
  background-color: #95c3de;
  left: 50%;
  animation-delay: -4s;
}
.confetti:nth-child(6) {
  background-color: #ff9a91;
  left: 60%;
  animation-delay: -6s;
}
.confetti:nth-child(7) {
  background-color: #90f24e;
  left: 70%;
  animation-delay: -1.5s;
}
.confetti:nth-child(8) {
  background-color: #95c3de;
  left: 80%;
  animation-delay: -2s;
}
.confetti:nth-child(9) {
  background-color: #ff9a91;
  left: 90%;
  animation-delay: -3.5s;
}
.confetti:nth-child(10) {
  background-color: #f2d74e;
  left: 100%;
  animation-delay: -2.1s;
}


.confetti:nth-child(11) {
    background-color: #f2d74e;
    left: 18%;
    animation-delay: 1.5;
  }
  .confetti:nth-child(12) {
    background-color: #95c3de;
    left: 30%;
    animation-delay: -5.5s;
  }
  .confetti:nth-child(13) {
    background-color: #ff9a91;
    left: 40%;
    animation-delay: -3.8s;
  }
  .confetti:nth-child(14) {
    background-color: #f2d74e;
    left: 50%;
    animation-delay: -3.4s;
  }
  .confetti:nth-child(15) {
    background-color: #95c3de;
    left: 60%;
    animation-delay: -4.7s;
  }
  .confetti:nth-child(16) {
    background-color: #ff9a91;
    left: 72%;
    animation-delay: -6.6s;
  }
  .confetti:nth-child(17) {
    background-color: #f2d74e;
    left: 78%;
    animation-delay: -1.9s;
  }
  .confetti:nth-child(18) {
    background-color: #95c3de;
    left: 87%;
    animation-delay: -2.7s;
  }
  .confetti:nth-child(19) {
    background-color: #ff9a91;
    left: 97%;
    animation-delay: -3.6s;
  }
  .confetti:nth-child(20) {
    background-color: #f2d74e;
    left: 11%;
    animation-delay: -2.8s;
  }


@keyframes confetti {
  0% {
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }
  25% {
    transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
  }
  50% {
    transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
  }
  75% {
    transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
  }
  100% {
    transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
  }
}
